import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DateAndTime from 'date-and-time';

import Tooltip from 'components/Layout/Tooltip';
import { oCurrencyFormatter } from 'utils/common';
import { currentUserLookupIdSelector } from 'redux/selectors';
import Accordion from '../../Layout/Accordion';
import { giftPropType } from './helpers';

const Gift = ({ data, forceExpanded }) => {
  const constituentLookupId = useSelector(currentUserLookupIdSelector);
  const constituentIsLegalDonor =
    (data.LEGAL_DONOR || {}).LOOKUPID === constituentLookupId;

  // All giving data before 1986 was rolled up into FY 1960
  const isRollupData = data.GIFT_DATE.includes('1960');
  let sDate = '';
  if (!data.GIFT_DATE || isRollupData) {
    sDate = 'Unknown';
  } else {
    const oDate = new Date(`${data.GIFT_DATE}T00:00`);
    sDate = DateAndTime.format(oDate, 'MM.DD.YYYY');
  }

  const bIsPending = data.TYPE === 'PENDING GIFT';

  return (
    <Accordion
      mTitle={
        <>
          {sDate}
          {data.TYPE === 'Matching Gift' && (
            <div className='myGiving__giftType'>matching gift</div>
          )}
        </>
      }
      sSize='small'
      bStartOpen={forceExpanded}
      className='myGiving__giftSection'
      sGtmCategory='Giving History: Gift'
    >
      <div className='myGiving__giftSectionContent'>
        {data.DESIGNATIONS.map((designation) => {
          let tooltipText = '';
          if (isRollupData) {
            switch (designation.TITLE) {
              case 'Conversion Gifts Unspecified':
                tooltipText =
                  'Donation details prior to 1986 are not available.  This summation represents your contributions to Wake Forest prior to 1986.';
                break;
              case 'Reynolda House Conversion Gifts':
                tooltipText =
                  'Donation details for these transactions are not available.  This summation represents your contributions to Reynolda House prior to its formal affiliation with Wake Forest.';
                break;
              default:
                break;
            }
          }
          const designationTitleNoSpaces = designation.TITLE.replace(
            /\s+/g,
            '-'
          );
          return (
            <div
              className='myGiving__rowWithLineAbove'
              key={`${designation?.TITLE}-${designation?.AMOUNT}`}
            >
              <span className='myGiving__giftDesignation t-paragraph--small'>
                {designation?.TITLE}
                {bIsPending && <em> &#40;pending&#41;</em>}
                {isRollupData && (
                  <Tooltip
                    sTriggerIcon='info'
                    ariaId={`pre-1986-giving-${designationTitleNoSpaces}-`}
                    sWrapperClassName='myGiving__rollupTooltip'
                  >
                    {tooltipText}
                  </Tooltip>
                )}
              </span>
              <span className='myGiving__giftAmount'>
                {oCurrencyFormatter.format((designation || {}).AMOUNT)}
              </span>
            </div>
          );
        })}
        {data.DESIGNATIONS.length > 1 && (
          <div className='myGiving__rowWithLineAbove'>
            <span className='myGiving__giftTotalLabel'>Total</span>
            <span className='myGiving__giftTotalAmount'>
              {oCurrencyFormatter.format(data.TOTAL_AMOUNT)}
            </span>
          </div>
        )}

        {!constituentIsLegalDonor && (
          <div className='myGiving__rowWithLineAbove myGiving__giftLegalDonor'>
            <span>Legal Donor</span>
            <span>{(data.LEGAL_DONOR || {}).NAME}</span>
          </div>
        )}
      </div>
    </Accordion>
  );
};

Gift.propTypes = {
  data: giftPropType.isRequired,
  forceExpanded: PropTypes.bool,
};

Gift.defaultProps = {
  forceExpanded: false,
};

export default Gift;
