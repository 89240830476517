/**
 * Builds a human-readable summary of the given record for labeling a "Contact Us" form submission.
 *
 * @param {string} sRecordType - Record type.
 * @param {object} oRecordData - Record data.
 * @returns {string} - Text to prefix note content.
 */
export const fBuildContactUsNotePrefix = (
  sRecordType = '',
  oRecordData = null
) => {
  if (!sRecordType || !oRecordData) {
    console.warn(
      'fBuildContactUsNotePrefix function requires both a record type and record data.'
    );
    return '';
  }

  const sLowercaseRecordType = sRecordType.toLowerCase();
  let sRecordDetail = '';
  switch (sLowercaseRecordType) {
    case 'employment':
      sRecordDetail = `${oRecordData.JOBTITLE} at ${oRecordData.EMPLOYER}`;
      break;
    case 'skills':
    case 'name': // intentional fall through
    case 'spouse': // intentional fall through
    case 'child': // intentional fall through
      sRecordDetail = oRecordData.NAME;
      break;
    case 'phone':
      sRecordDetail = oRecordData.NUMBER;
      break;
    case 'email':
      sRecordDetail = oRecordData.EMAILADDRESS;
      break;
    case 'Address':
      sRecordDetail = `${oRecordData.ADDRESSBLOCK}, ${oRecordData.ADDRESSCITY}, ${oRecordData.ADDRESSSTATEABBRV} ${oRecordData.ADDRESSZIP},`;
      break;
    case 'parental':
    case 'familial': // WFU Family - intentional fall through
      sRecordDetail = oRecordData.NAME;
      break;
    case 'religion':
      sRecordDetail = oRecordData.DESCRIPTION;
      break;
    case 'group':
      sRecordDetail = `${oRecordData.POSITION} at ${oRecordData.ORGANIZATIONNAME}`;
      break;
    default:
      break;
  }

  return `Record: ${sRecordDetail}*linebreak*Note: `;
};

export default { fBuildContactUsNotePrefix };
